import api from '@apiInstance';

export default {
  add(params) {
    return api.post('company_contacts', params);
  },
  async get(id) {
    const response = await api.fetch('company_contacts/' + id);
    return response;
  },
  async list(params) {
    const response = await api.fetch('/company_contacts', { params });
    return response;
  },
  update(params) {
    return api.patch('company_contacts', params);
  },
  delete(id) {
    return api.remove('company_contacts', id);
  },
};
